<template>
<div class="style-guide">
  <v-container fluid>
    <v-row>
      <v-col md="2" class="ty-annotation my-10">
        color
      </v-col>
      <v-col md="10" class="d-flex">
        <div class="ma-10 color">
          <div class="dot bg-color-yellow"/>
        </div>
        <div class="ma-10 color">
          <div class="dot bg-color-dark"/>
        </div>
        <div class="ma-10 color">
          <div class="dot bg-color-light"/>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2" class="ty-annotation my-10">
        typography
      </v-col>
      <v-col md="10">
        <div v-for="(t,i) of typos"
             :key="i"
             class="typo py-5"
             :class="t.class" v-html="t.text"/>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
export default {
  name: "style-guide",
  data:function(){
    return{
      typos:[
        {
          class:"ty-title-page",
          text:'TITLE PAGE'
        },
        {
          class:"ty-title-section",
          text:'TITLE section'
        },
        {
          class:"ty-title-paragraph",
          text:'TITLE paragraph'
        },
        {
          class:"ty-title-link",
          text:'TITLE link'
        },
        {
          class:"ty-multiline-text",
          text:'Multiline text'
        },
        {
          class:"ty-link",
          text:'Link'
        },
        {
          class:"ty-title-paragraph",
          text:'TITLE PARAGRAPH - 20PX - RICHTEXT H1'
        },
        {
          class:"ty-rich-text",
          text:'Paragraph Lorem ipsum dolor sit amet, amLorem ipsum dolor sit amet, amet, consecteet, consectetuer adipiscing. hermesdeckung. Lorem ipsum dolor sit amet, am. Link text bold Lorem ipsum dolor sit amet, amet, consecteet, consectetuer adipiscing. hermesdeckung + <b>bold</b> + <a href="#">link</a> - 17px - Richtext p'
        },
        {
          class:"ty-annotation",
          text:'Annotation : Lorem ipsum dolor sit amet - richtext H5'
        }
      ]
    }
  }
}
</script>

<style lang="less">
.style-guide{
  .color{
    .dot{
      width: 100px;height: 100px;border-radius: 100%;border: 1px solid #888;
    }
  }
  .typo{
    border-top:1px solid #333;
  }
}
</style>